var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('v-app',{class:{ 'pattern-bg': _vm.light, 'pattern-bg-dark': _vm.dark }},[(_vm.menu != 'none')?_c('v-navigation-drawer',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"elevation-2",attrs:{"mini-variant":_vm.mini,"expand-on-hover":_vm.$vuetify.breakpoint.mdAndUp,"app":"","clipped":""},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-scroll-x-reverse-transition',{attrs:{"group":"","hide-on-leave":""}},[(_vm.menu === 'dashboard')?_c('MenuDashBoardColaborador',{key:0}):_vm._e()],1)],1):_vm._e(),_c('v-app-bar',{staticClass:"elevation-2",class:{ primary: _vm.light, 'panel-gradient-bg-dark': _vm.dark },attrs:{"app":"","clipped-left":"","dark":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer},"click:outside":function($event){_vm.drawer = !_vm.drawer},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.drawer = !_vm.drawer}}}),_c('h4',{staticClass:"text-h6"},[_vm._v("COLABORADOR")]),_c('v-spacer'),(_vm.isAdmin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","to":{ path: '/admin' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-shield-account ")])],1)]}}],null,false,3858073386)},[_c('span',[_vm._v("Sistema Admin")])]):_vm._e(),(_vm.isGestor)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","to":{ path: '/gestor' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-account-cog ")])],1)]}}],null,false,683800521)},[_c('span',[_vm._v("Sistema Gestor")])]):_vm._e(),_c('div',{staticClass:"d-flex justify-center align-center mr-3"},[_c('v-avatar',{attrs:{"size":"30"}},[_c('v-img',{attrs:{"src":_vm.store_avatar
              ? _vm.store_avatar
              : require('@/assets/avatar_placeholder.png')}})],1),_c('div',{staticClass:"ml-2 text-body-1"},[_vm._v(_vm._s(_vm.usuario_name))])],1),_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){_vm.menuUsuario = !_vm.menuUsuario}}},[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)],1),(_vm.menuUsuario)?_c('MenuLateralDireitoColaborador',{attrs:{"menuUsuario":_vm.menuUsuario},on:{"update:menuUsuario":function($event){_vm.menuUsuario=$event},"update:menu-usuario":function($event){_vm.menuUsuario=$event}}}):_vm._e(),_c('v-main',{staticStyle:{"z-index":"1"}},[_c('transition',{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1),_c('v-footer',{staticClass:"elevation-2",class:{ primary: _vm.light, 'panel-gradient-bg-dark': _vm.dark },attrs:{"height":"30px","app":"","inset":"","dark":""}}),_c('v-card',{staticStyle:{"background":"#eeeeee","position":"fixed","right":"0","bottom":"22px"},attrs:{"tile":"","flat":"","width":"100px","height":"100px"}},[_c('v-img',{attrs:{"src":require('@/assets/logo_bg.png')}})],1)],1):_c('v-app',[_c('v-main',[_c('v-container',{staticClass:"fill-height align-center justify-center"},[_c('v-progress-circular',{attrs:{"width":5,"indeterminate":"","color":_vm.dark ? 'white' : 'primary',"size":"80"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }